* {
    box-sizing: border-box;
}

.files-dropzone {
    background-color: lightgray;
    border: 3px solid gray;
    border-radius: 10px;
    font-size: large;
    text-align: center;
    padding: 20px;
    margin: 10px;
}

.error-box {
    background-color: rgb(250, 95, 119);
    border: 3px solid red;
    border-radius: 10px;
    font-size: large;
    text-align: center;
    padding: 10px;
    margin: 10px;
}